.settingsWrap {
  padding: 1em 0 0 11em;
  overflow: hidden;
}

.settingsSelect {
  width: 8em;
  margin-right: 1.5em !important;
}

.settingsOpen {
  max-height: 150px;
  transition: max-height 0.3s ease-in;
}

.settingsHidden {
  max-height: 0;
  transition: max-height 0.2s ease-in;
}

@media only screen and (max-width: 640px) {
  .settingsWrap {
    padding: 0;
    padding-top: 1em;
    margin: 0 auto;
    width: 95%;
  }

  .settingsSelect {
    width: calc(33.3% - 0.67em);
    margin-right: 1em !important;
  }

  .settingsSelect:last-child {
    margin: 0 !important;
  }
}