.wrap {
  outline: none;
  text-align: left;
}

.inputWrap {
  width: 100%;
  border: 3px solid orange;
  border-radius: 1em;
  padding-left: 1em;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inputWrap.extended {
  box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.2)
}

.wrap ul {
  margin: 0 auto;
  margin-right: 1em;
  padding: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
  border-top: thin solid #E0E0E0;
  box-sizing: border-box;
  background-color: white;
}

.hide {
  display: none;
}

.wrap ul li {
  list-style: none;
}

.wrap ul li:hover, .selected {
  cursor: pointer;
  background-color: #fafafa;
}

.wrap ul li p {
  margin: 0;
  padding-left: 0.5em;
  line-height: 2em;
  font-size: 1em;
}

.inputWrap input {
  width: calc(100% - 2em);
  box-sizing: border-box;
  height: 2.5em;
  border: none;
  outline: none;
  font-size: 1em;
  padding-left: 0.5em;
}

.inputWrap button {
  box-sizing: border-box;
  background: none;
  outline: none;
  border: none;
  font-size: 2em;
  padding: 0;
  margin: 0;
  color: rgb(0, 0, 0, 0.4);
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .inputWrap input {
    height: 1.5em;
  }

  .inputWrap button {
    font-size: 1.5em;
  }
  
  .wrap ul li p {
    font-size: 0.95em;
  }
}
