.authWrap button {
  margin-right: 1em;
}

.authWrap span {
  font-weight: 400;
  color: white;
}

.authWrap :global(.MuiAvatar-root) {
  margin-right: 0.5em;
}

.authWrap :global(.MuiAvatar-root):hover {
  cursor: pointer;
}

.button span {
  color: black;
}

@media only screen and (max-width: 640px) {
  .headerWrap {
    height: 5.5em;
  }

  .authWrap {
    padding-top: 0;;
  }

  .authWrap button {
    margin-right: 1em;
    border: none;
  }

  .authWrap button:last-child {
    margin-right: 0;
  }
}



