.results-wrap {
  max-width: 650px;
  margin-left: 11em;
  height: auto;
  padding-right: 1em;
  box-sizing: border-box;
  width: calc(100% - 7em);
  order: 1;
}

.results-wrap p {
  font-size: 0.9em;
}

.search-results-bg {
  display: flex;
  flex-wrap: wrap;
}

.pagination-wrap {
  margin-bottom: 2em;
  text-align: center;
}

.search-results-end-wrap {
  width: 100%;
}

.search-results-end-wrap p {
  display: inline-block;
  width: 50%;
  margin-top: 0;
}

.search-results-end-wrap a {
  display: inline-block;
  width: 50%;
  text-align: right;
  font-size: 0.8em;
  color: #455a64;
}

.no-search-results-wrap {
  width: 85%;
  margin: 0 auto;
  margin-top: 2em;
}

.related-searches-wrap {
  margin-top: 3em;
  margin-bottom: 1em;
  font-size: 0.9em;
}

.related-searches-links a {
  display: inline-block;
  min-width: fit-content;
  font-size: 0.95em;
}

.queryContext h2 {
  font-weight: 400;
  font-size: 1.3em;
  margin-bottom: 0.2em;
}

.queryContext p {
  margin-top: 0;
}

.computation-result-wrap {
  padding: 0.2em 1.5em;
  margin-bottom: 1em;
}

.computation-result-wrap h2 {
  font-weight: 400;
}

.sidebar-wrap {
  width: 350px;
  margin-left: 40px;
  order: 1;
  margin-top: 3em;
}

.sidebar-top-wrap {
  width: 100%;
  display: flex;
  position: relative;
}

.sidebar-main-img {
  height: 150px;
  width: 34%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sidebar-side-img-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 66%;
}

.sidebar-side-img {
  height: 75px;
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sidebar-top-single-image {
  padding-top: 1em;
  padding-left: 1.5em;
}

.sidebar-top-single-image img {
  max-height: 100px;
}

.sidebar-details {
  padding: 0 1.5em 1em 1.5em;
}

.sidebar-details h2 {
  font-weight: 400;
}

.sidebar-details p {
  font-size: 0.9em;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-details .link {
  display: inline-block;
  width: min-content;
  text-align: center;
  margin-right: 1em;
  vertical-align: top;
}

.sidebar-details .link p {
  margin: 0;
  font-size: 0.8em;
}

.sidebar-wrap .footer {
  font-size: 0.7em;
  padding: 1em 1em 1em 2em;
}

.sidebar-wrap .footer p {
  margin: 0;
}

.more-images-link {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  color: white !important;
  font-size: 0.9em;
  text-shadow: 1px 1px 2px rgb(0, 0, 0, 0.8);
}

@media only screen and (max-width: 1152px) {
  .sidebar-wrap {
    order: 0;
    width: 100%;
    max-width: 635px;
    margin: 1em 1.8em 1em 11em;
  }
}

@media only screen and (max-width: 640px) {
  .search-results-bg {
    background-color: whitesmoke;
    flex-direction: row-reverse;
    padding-bottom: 0.5em;
  }
  .results-wrap {
    width: 100%;
    max-width: none;
    margin: 0;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 1.5em;
  }
  .related-searches-wrap {
    width: 95%;
    margin: 2em auto 1em auto;
  }

  .queryContext h2 {
    margin-top: 0;
  }

  .queryContext p {
    margin-bottom: 0;
  }

  .sidebar-wrap {
    width: 94%;
    margin: 1em auto 0 auto;
  }
}