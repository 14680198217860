.stylish {
  width: 3em;
  height: 3em;
  border-radius: 3em;
  border: 0.4em double #ccc;
  color: #fff;
  text-align: center;
  background: #2C8B30;
  flex-shrink: 0;
}
  
.inactive {
  border: 0.4em double #ccc;
  background: #e6e6e6;
}

.stylish p {
  line-height: 1.8em;
  color: white;
  margin: 0;
  font-size: 1.8em;
}