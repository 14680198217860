.log-in-form-container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  flex-wrap: wrap;
  text-align: center;
}

.log-in h2 {
  margin: 0;
  margin-bottom: 1.5em;
  font-weight: 400;
}

.lock-icon {
  color: #455a64;
  margin-top: 1em;
}

.log-in-form-wrap {
  width: 85%;
  margin: 0 auto;
}

.log-in-form-wrap .forgot-password-link {
  display: inline-block;
  width: 40%;
  text-align: left;
  vertical-align: middle;
}

.log-in-form-wrap .sign-up-link {
  display: inline-block;
  width: 60%;
  text-align: right;
  vertical-align: middle;
}

@media only screen and (max-width: 640px) {
  .log-in-form-wrap {
    width: 100%;
  }
}