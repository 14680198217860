.profile-element {
  width: 90%;
  max-width: 800px;
  padding-top: 20px;
  margin: 0 auto;
}

.profile-element h2 {
  color: white;
  margin-bottom: 0;
}

.profile-element h3 {
  color: white;
  margin-top: 0.5em;
  font-weight: 400;
  font-size: 1.3em;
}

.profile-element h3 a {
  color: white;
}

.profile-element p {
  margin-left: 0.6em;
}

.change-password button {
  background-color: #2196f3;
  margin: 1em 0;
}

.change-password button:hover {
  background-color: #1976d2;
}

.change-password button span {
  color: white;
}

.change-password {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

.profile-element .MuiFormHelperText-root {
  font-size: 1em;
}