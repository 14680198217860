.headerWrap {
  background-color: white;
  height: 4em;
  box-shadow: 1px 4px 4px #E0E0E0;
  position: relative;
}

.avatar {
  position: absolute;
  top: 0.7em;
  right: 1.5em;
}

.avatar:hover {
  cursor: pointer;
}

.buttonsWrap {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4em;
  padding-left: 1em;
}

.logo img {
  height: 3em;
}

:global(.MuiMenu-paper) {
  margin-top: 2.5em;
}

@media only screen and (max-width: 640px) {
  :global(.search-results-bg) .headerWrap {
    height: 6.5em;
  }

  .headerWrap {
    height: 6.5em;
  }

  :global(.search-results-bg) .headerWrap.small {
    height: 4em !important;
  }

  .headerWrap.small {
    height: 4em !important;
  }

  .avatar {
    position: absolute;
    top: 0.7em;
  }

  .avatar :global(.MuiAvatar-root) {
    width: 1.7em;
    height: 1.7em;
  }
}