.registration-form-wrap {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  flex-wrap: wrap;
}

.stepper-wrap {
  margin-top: 3em;
  margin-bottom: 2em;
  width: 100%;
}

.stepper-wrap .MuiStepper-root {
  padding: 0;
  margin: 0 auto;
  max-width: 900px;
}

.transitionRight-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.transitionRight-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.transitionRight-exit {
  opacity: 1;
  transform: translateX(0%);
}
.transitionRight-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.transitionRight-enter-active,
.transitionRight-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.on-registration-back-drop {
  z-index: 999 !important;
  color: #fff;
}

.register-log-in-wrap {
  width: 100%;
  text-align: center;
}

.register-log-in-link {
  display: inline-block;
  width: 90%;
  max-width: 700px;
  color: #3f51b5;
  margin-top: 1em;
  text-align: left;
}

.register-log-in-link:hover {
  text-decoration: underline;
}
