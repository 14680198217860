.images-results-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1em;
}

.images-results-wrap span {
  margin-left: 0.5em;
  margin-right: 0.5em;
  transform: scale(1, 0.9) !important;
}

.image-result-img {
  height: 10em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0.3em;
  border-radius: 5px;
}

.image-result-img:hover {
  cursor: pointer;
}

.images-results-footer-wrap {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

@media only screen and (max-width: 790px) {
  .images-results-wrap {
    background-color: white;
    padding-bottom: 1em;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
  }

  .images-search-results-divider {
    display: none;
  }

  .images-results-wrap a {
    width: 29%;
    margin-left: 0.3em;
    margin-right: 0.3em;
  }

  .image-result-img {
    height: 6em;
    width: 100% !important;
    box-sizing: border-box;
  }
}