.searchResult {
  width: 100%;
  border: thin solid transparent;
  padding: 10px;
  margin-left: -10px;
}

.searchResult:hover, .searchResult.selected {
  border: thin solid rgb(0, 0, 0, 0.2);
  border-radius: 7px;
  cursor: default;
}

.searchResult h2 a {
  font-weight: 400;
  font-size: 0.8em;
  color: #1a0dab;
  text-decoration: none;
}

.searchResult h2 a:visited, .deep-link h3 a:visited {
  color: #7e359e;
}

.searchResult h2 a:hover {
  text-decoration: underline;
}

.searchResult p img {
  width: 21px;
  height: 21px;
  margin-right: 5px;
  margin-bottom: 2px;
  vertical-align: middle;
}

.searchResult p, .searchResult h2 {
  margin: 0;
}

.searchResult .url {
  font-size: 0.8em;
  color: green;
  margin-bottom: 3px;
  word-wrap: break-word;
}

.searchResult .snippet {
  font-size: 0.9em;
  line-height: 1.5em;
}

.deepLinksWrap {
  margin-top: 1em;
  margin-left: 1em;
  display: flex;
  flex-wrap: wrap;
}

.deepLink {
  width: 44%;
  max-height: 100px;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 1em;
  overflow: hidden;
}

.deepLink h3 {
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  margin-bottom: 0.2em;
}

.deepLink h3 a {
  font-weight: 400;
  font-size: 0.8em;
  color: #1a0dab;
  text-decoration: none;
}

.deepLink h3 a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.deepLink .snippet {
  font-size: 0.8em;
}

@media only screen and (max-width: 640px) {
  .searchResult, :global(.queryContext) {
    border-radius: 10px;
    background-color: white;
    padding: 1em;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 1em;
    box-shadow: 1px 4px 4px #E0E0E0;
    border: none;
  }
}