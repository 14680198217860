.causes-wrap {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  padding-bottom: 2em;
}

.causes-wrap p {
  font-size: 1.2em;
}

.causes-element {
  width: 90%;
  max-width: 800px;
  padding-top: 20px;
  margin: 0 auto;
}

.causes-element h2 {
  color: white;
  margin-bottom: 0;
}

.causes-element h3 {
  color: white;
  margin-top: 0.5em;
  font-weight: 400;
  font-size: 1.3em;
}

.causes-element h3 a {
  color: white;
}

.causes-element p {
  margin-left: 0.6em;
}

.cause-container {
  width: 90%;
  display: flex;
  align-items: center;
  margin: 1em auto 3em auto;
}

.cause-container img {
  width: 30%;
  margin-right: 1em;
}

.cause-container h2 {
  color: #455a64;
}

.cause-title {
  color: #455a64 !important;
  margin-left: 1.7em;
}

.cause-container p {
  margin-left: 0;
}

.cause-details h2 {
  margin-top: 0;
}

.cause-details p {
  margin-bottom: 0;
}

@media only screen and (max-width: 760px) {
  .cause-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .cause-container img {
    width: 95%;
    max-width: 300px;
  }

  .cause-details {
    width: 100%;
    margin: 2em auto 0 auto;
  }

  .cause-title {
    margin-left: 0.5em;
  }
}
