.wrap h2 {
  margin: 0;
  margin-bottom: 0.3em;
  font-weight: 300;
  text-align: center;
}

.wrap h3 {
  margin: 0;
  margin-top: 1em;
}

.wrap p {
  margin: 0;
  margin-top: 0.5em;
}

.wrap button {
  margin-top: 2em;
  width: 120px;
}

.wrap button span {
  color: white;
}