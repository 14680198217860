.mainSearchWrap {
  width: 84%;
  max-width: 500px;
  position: absolute;
  top: 0.55em;
  left: 11em;
  z-index: 99;
}

.searchResultsHeader {
  height: 6em !important;
}

.logo {
  width: 4.5em;
  position: absolute;
  top: 0.5em;
  left: 2.5em;
}

.logo:hover {
  cursor: pointer;
}

.menu {
  position: absolute;
  bottom: 0;
  left: 11em;
}

.customMenu {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.menu ul {
  margin: 0;
  padding: 0;
}

.menu ul li {
  display: inline-block;
  margin-right: 1.5em;
}

.menu ul li a {
  color: inherit;
}

.menu ul li div:hover a, .active a {
  color: #3C7B3E !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.menu ul div {
  display: inline-block;
  border-bottom: 3px solid transparent;
  margin-bottom: -3px;
  padding-bottom: 0.6em;
}

.menu ul div a {
  font-size: 0.9em;
}

.menu ul li div:hover {
  border-bottom: 3px solid #3C7B3E;
  cursor: pointer;
}

.menu ul li:last-child {
  margin: 0;
  margin-left: 1em;
}

.customMenu ul li:last-child {
  margin-left: 0 !important;
}

.settings:hover, .settings.active {
  border-bottom: 3px solid transparent !important;
}

.active {
  border-bottom: 3px solid #3C7B3E !important;
}

.icon {
  vertical-align: middle;
  margin-bottom: 0.1em;
  margin-right: -0.15em;
  font-size: 1.1em !important;
}

.otherIcon {
  margin-right: 0.1em;
}

.noTabs {
  height: 4.2em !important;
}

@media only screen and (max-width: 640px) {
  .mainSearchWrap {
    top: 3.2em;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: 90%;
  }

  .logo {
    top: 0.3em;
    left: 2em;
    width: 2.5em;
  }

  .searchResultsHeader {
    height: 8em !important;
  }

  .noTabs {
    height: 6.7em !important;
  }

  .menu {
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
  }

  .menu ul li {
    margin-right: 0.8em;
  }

  .menu ul li:last-child {
    margin: 0 !important;
  }

  .icon {
    font-size: 1em !important;
  }
}

@media only screen and (max-width: 340px) {
  .menu ul li {
    font-size: 0.95em;
  }
}
