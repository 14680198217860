.fieldWrap {
  margin: 0 auto;
  margin-top: 1em;
}

.fieldWrap:last-child {
  margin-bottom: 3em;
}

.button {
  margin-top: 2em !important;
  margin-bottom: 1em !important;
}

.button span {
  color: white;
}

.button svg {
  color: white;
  margin: 0;
}

.alert {
  margin-top: 1em;
}