.searchResult {
  margin-bottom: 1.5em;
  width: 100%;
}

.searchResult h2 img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 2px;
  vertical-align: middle;
}

.searchResult h2 a {
  font-weight: 400;
  font-size: 0.8em;
  color: #1a0dab;
  text-decoration: none;
}

.searchResult h2 a:visited {
  color: #7e359e;
}

.searchResult h2 a:hover {
  text-decoration: underline;
}

.searchResult p, .searchResult h2 {
  margin: 0;
}

.searchResult .url {
  font-size: 0.8em;
  color: green;
  margin-bottom: 3px;
  word-wrap: break-word;
}

.descWrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 0.5em;
  flex-wrap: wrap;
}

.img {
  border-radius: 8px;
  position: relative;
  height: 100px;
  width: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.searchResult .snippet {
  font-size: 0.9em;
  width: calc(100% - 160px - 1em);
  margin-left: 1em;
}

@media only screen and (max-width: 640px) {
  .searchResult {
    border-radius: 10px;
    background-color: white;
    padding: 1em;
    box-sizing: border-box;
    margin-bottom: 1em;
    box-shadow: 1px 4px 4px #E0E0E0;
  }

  .searchResult .snippet {
    width: 100%;
    margin-left: 0;
    margin-top: 1em;
  }
}