.wrap {
  padding: 0.2em 1.5em 1.5em 1.5em;
  margin-bottom: 1em;
}

.wrap h2 {
  font-weight: 400;
  margin-bottom: 0.2em;
}

.wrap p {
  margin: 0;
}

.divider {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.wrap :global(.MuiTableCell-root) {
  border-bottom: none !important;
}

.table {
  width: 100%;
  max-width: 500px;
}

.timeCell {
  width: 25%;
  min-width: 126px;
  box-sizing: border-box;
}