.highlight {
  background-color: #FFFFCC;
}

.marky {
  font: 200 16px system-ui;
  overflow: hidden;
  color: #E28411; 
  float: center;
  text-align: center;
  margin: auto;
}

.responsive {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.trees {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.start-text-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: fit-content;
}

.start-text-centered p {
  color: white;
  font-size: 1.1em;
  margin: 0;
  margin-top: 0.3em;
  text-shadow: 0px 1px 1px rgb(0, 0, 0);
}

.start-text-centered p span {
  color: white;
}

.start-text-bottom {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  width: fit-content;
}

.start-text-bottom p {
  color: white;
  font-size: 1.1em;
  margin: 0;
  margin-top: 0.3em;
  margin-left: 0.5em;
  text-shadow: 0px 1px 1px rgb(0, 0, 0);
}

.home-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.start-section {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #ffff;
  background-image: url(../../assets/aerial.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.start-section .search-wrap img {
  margin-bottom: 1em;
  height: 6em;
}

.start-section .search-wrap {
  max-width: 600px;
  width: 90%;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  color: #35AE39;
  z-index: 1;
}

.start-section .bgimage {
  max-width: 2500px;
  width: 80%;
  position: relative;
  top: 30%;
  margin-left: auto;
  margin-right: auto;
}

.start-section .learn-more {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.start-section .learn-more p {
  margin: 0;
  margin-top: 1em;
  color: white;
  text-shadow: 0px 2px 2px rgb(0, 0, 0);
  font-size: .9em;
}

.start-section .learn-more svg {
  fill: white;
  font-size: 3em;
  margin-top: -0.2em;
}

.start-section .learn-more:hover p {
  text-decoration: underline;
}

.start-section .learn-more:hover {
  cursor: pointer;
}

.about-section {
  height: auto;
  width: 100%;
  position: relative;
  background-image: linear-gradient(white, whitesmoke);
  padding-bottom: 2em;
}

.home-wrap h2 {
  text-align: center;
  font-weight: 400;
}

.home-wrap h3 {
  text-align: center;
  font-weight: 250;
}

.about-content {
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
  margin-top: 4em;
  display: flex;
  align-items: center;
}

.about-content .power {
  margin: 0;
  display: inline-block;
  width: fit-content;
  text-align: center;
  transform: rotate(-10deg);
  font-size: 1.5em;
  color: lightgrey;
  font-style: italic;
}

.about-content div {
  margin-left: 4em;
  padding-left: 4em;
  border-left: 2px solid rgb(0, 0, 0, 0.1);
}

.about-content div p {
  margin: 0;
  margin-bottom: 1em;
  font-size: 1em;
}

.small {
  font-size: 0.8em;
}

.smally {
  font-size: 0.8em;
  float: right;
  color: #dcdcdc;
}

.about-content div p:last-child {
  margin-bottom: 0;
}

.more-ways-section {
  padding: 2em 0;
  background-color: white;
}

.devices-container {
  width: 100%;
  max-width: 650px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.device-element {
  width: 20%;
  height: 7em;
  text-align: center;
  position: relative;
}

.device-element p {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.device-element svg {
  width: 4em;
}

.device-element svg path {
  color: rgb(0, 0, 0, 0.3);
}

.device-element:last-child {
  padding: 0.5em;
  border: thin solid rgb(0, 0, 0, 0.3);
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.2);
  margin-left: 2em;
  height: 6em;
  background-color: white;
  width: 9em;
}

.device-element:last-child a {
  color: #455a64;
}

.device-element:hover {
  cursor: pointer;
}

.how-uzvy-works-container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
}

.how-uzvy-works-container div p {
  font-size: .9em;
}

.privacy-section {
  padding: 2em 0;
  background-color: whitesmoke;
}

.privacy-element-container {
  width: 90%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  margin: 2em auto;
}

.privacy-element {
  width: 32%;
  background-color: white;
  border-radius: 15px;
  border: thin solid rgb(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.1);
  padding: 1em;
  box-sizing: border-box;
}

.privacy-element-image {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-section {
  background-color: whitesmoke;
  padding: .2em 0;
}

.footer-section h2 {
  display: flex;
  align-items: center;
}

.footer-section h2 img {
  height: .8em;
  margin-right: 0.2em;
}

.footer-container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.footer-content {
  margin-left: 3em;
}

.footer-mission {
  font-size: 1em;
  transform: rotate(-5deg);
  color: lightgrey;
  font-style: italic;
}

.my-account-link-wrap {
  text-align: right;
  width: fit-content;
  display: inline-block;
  position: absolute;
  right: 2em;
  top: 0.7em;
}

.how-uzvy-works-section {
  background-color: white;
  padding: 2em 0;
}

.footer-links {
  list-style: none;
  padding-left: 2em;
}

.footer-links li a {
  color: inherit;
}

.drawer-item {
  min-width: 15em;
}

.drawer-item-close {
  width: 100%;
  text-align: right;
  padding-top: 0.5vw;
}

.homepage-auth-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5em;
  padding-top: 0.5em;
  width: 100%;
}

.homepage-auth-buttons svg {
  color: white;
}

.resultsFromMSBing {
  width: 100%;
  text-align: right;
}

.resultsFromMSBingImg {
  height: 1.5em !important;
  width: unset !important;
}

.info-bar {
  width: 100%;
  text-align: center;
  background-color: #EEE8AA;
  padding: 0.75em 1em;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.5);
}

.info-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.info-bar.scrolling-bar {
  margin-top: 2.8em;
}

.info-bar p {
  margin: 0;
}

@media only screen and (max-width: 640px) {
  .my-account-link-wrap {
    top: 1em;
  }

  .home-wrap h2 {
    width: 90%;
    margin: 1em auto;
  }

  .about-content {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-content div {
    margin-left: 0;
    padding-left: 0;
    margin-top: 2em;
    border-left: none;
    width: 100%;
  }

  .about-content div p {
    font-size: 1em;
  }

  .device-element {
    width: 50% !important;
  }
  .device-element:last-child {
    width: calc(50% - 4em) !important;
    margin-right: 2em;
  }

  .privacy-element-container {
    flex-wrap: wrap;
  }
  
  .privacy-element {
    width: 100%;
    margin-bottom: 2em;
  }

  .privacy-element:last-child {
    margin-bottom: 0;
  }

  .footer-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer-content {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .footer-section h2 {
    font-size: 1.2em;
    justify-content: center;
  }
  
  .footer-section h2 img {
    height: 1.2em;
    margin-right: 0.2em;
  }

  .footer-links {
    padding-left: 0;
  }
  
  .start-text-centered p {
    font-size: 4.2vw;
  }
  
  .start-text-bottom p {
    font-size: 4vw;
  }

  .start-section .learn-more p {
    font-size: 4vw;
  }
  
  .start-section .learn-more svg {
    font-size: 6vw;
  }

  .resultsFromMSBingImg {
    height: 1.5em !important;
  }
}