.pagination a {
  margin-left: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 300;
  vertical-align: middle;
}

.current {
  font-weight: 900 !important;
}

.pagination svg {
  vertical-align: middle;
  font-weight: 100;
}