.title {
  margin: 0;
  margin-bottom: 0.3em;
  font-weight: 300;
  text-align: center;
}

.buttonWrap {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 2em;
}

.buttonWrap button span {
  color: white;
}