.wrap h2 {
  margin: 0;
  margin-bottom: 0.3em;
  font-weight: 300;
  text-align: center;
}

.wrap button {
  margin-top: 2em;
  width: 120px;
}

.wrap button span {
  color: white;
}

.wrap label {
  margin-top: 0.5em;
}

.wrap label p {
  margin: 0;
}

.textWrap {
  height: 40vh;
  overflow-y: scroll;
  margin-top: 0.8em;
}

.fineprint {
  font-size: 0.69em;
  margin-bottom: 0;
}

.wrap span {
  color: unset;
}

.wrap label span {
  color: #455a64;
}

.wrap label span span {
  color: unset;
}