.title {
  margin: 0;
  margin-bottom: 0.3em;
  font-weight: 300;
  text-align: center;
}

.link:hover {
  cursor: pointer;
}

.textWrap {
  margin-top: 12vh;
  text-align: center;
}