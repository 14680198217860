.my-account-wrap {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  padding-bottom: 2em;
}

.my-account-wrap p {
  font-size: 1.2em;
}

.my-account-element {
  width: 90%;
  max-width: 800px;
  padding-top: 20px;
  margin: 0 auto;
}

.my-account-element h2 {
  color: white;
  margin-bottom: 0;
}

.my-account-element h3 {
  color: white;
  margin-top: 0.5em;
  font-weight: 400;
  font-size: 1.3em;
}

.my-account-element h3 a {
  color: white;
}

.my-account-element p {
  margin-left: 0.6em;
}

.my-account-element a {
  text-decoration: none !important;
}

.giftcard-wrap {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.8em;
}

.giftcard-wrap h3 {
  margin: 0;
  margin-left: 0.8em;
}

.prizes-fineprint {
  margin-top: -0.8em;
  font-size: 1em !important;
  text-align: right;
}

.my-account-element button {
  margin-left: 0.5em;
}


.entry-wrap {
  width: 95%;
  margin: 0 auto;
  margin-top: 1em;
}

.entry-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.entry-title h3 {
  color: #455a64;
  font-weight: 500;
  margin: 0;
  margin-left: 0.8em;
}

.entry-description {
  margin-left: 5.5em;
  margin-top: 0.4em;
  margin-bottom: 3em;
}

.entry-description p {
  color: #455a64;
  margin: 0;
  margin-bottom: 0.8em;
}

.inactive h3, .inactive p {
  color: rgba(0, 0, 0, 0.4);
}

.nonUsNotice {
  width: 90% !important;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1em;
  text-align: center;
}

.nonUsNotice span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nonUsNotice p {
  color: red;
}

.nonUsNotice svg {
  color: red;
  margin-right: 0.5em;
}