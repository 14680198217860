.container {
  padding: 0.5em 1.5em 0.8em 1.5em;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1em;
}

.container h2 {
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.imgsWrap {
  overflow: hidden;
  overflow-x: scroll;
}

.imgsScroll {
  width: max-content;
}

.imgsScroll a {
  margin-right: 0.8em;
}

.imgsScroll a img {
  border-radius: 10px;
  box-shadow: 1px 1px 5px gray;
}

.container img:last-child {
  margin-right: 0;
}

.more {
  margin-top: 1em;
}

.imgsWrap::-webkit-scrollbar {
    height: 0;
}

.navigation {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  border: thin solid rgb(0, 0, 0, 0.2);
  color: rgb(0, 0, 0, 0.4);
}

.navigation:hover {
  color: rgb(0, 0, 0, 0.6);
  cursor: pointer;
}

.back {
  left: 0.5em;
}

.forward {
  right: 0.5em;
}