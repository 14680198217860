.wrap {
  margin-bottom: 0.5em;
}

.descWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.thumb {
  width: fit-content;
  margin-top: -1.5em;
}

.descLines {
  width: calc(100% - 160px - 1em);
  margin-left: 1em;
}