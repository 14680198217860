.password-reset-form-container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  flex-wrap: wrap;
  text-align: center;
}

.password-reset h2 {
  margin: 0;
  margin-bottom: 1.5em;
  font-weight: 400;
}

.password-reset-form-wrap {
  width: 85%;
  margin: 0 auto;
}

.password-reset-form-wrap .forgot-password-link {
  display: inline-block;
  width: 40%;
  text-align: left;
  vertical-align: middle;
}

.password-reset-form-wrap .sign-up-link {
  display: inline-block;
  width: 60%;
  text-align: right;
  vertical-align: middle;
}

.password-reset-button {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.password-reset-button span {
  color: white;
}

.alert {
  margin-top: 1em;
}

@media only screen and (max-width: 640px) {
  .password-reset-form-wrap {
    width: 100%;
  }
}