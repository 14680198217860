.wrap {
  position: relative;
}

.wrap h2 {
  font-weight: 300;
  margin-top: 0;
}

.container {
  width: 200px;
  display: inline-block;
  margin-right: 13px;
  margin-left: 2px;
}

.container:last-child {
  margin-right: 0;
}

.thumb {
  height: 90px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.container a {
  width: 90%;
  margin: 1em auto;
  height: 3.6em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container p {
  display: block;
  width: 90%;
  margin: 0 auto 1em auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.more {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.containerWrapper {
  width: max-content;
}

.scroll {
  overflow-x: scroll;
}

.scroll::-webkit-scrollbar {
  height: 0;
}

.navigation {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  border: thin solid rgb(0, 0, 0, 0.2);
  color: rgb(0, 0, 0, 0.4);
  z-index: 9;
}

.navigation:hover {
  color: rgb(0, 0, 0, 0.6);
  cursor: pointer;
}

.back {
  left: -1.5em;
}

.forward {
  right: -1em;
}

.imgOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0.7;
}

.imgOverlay :global(.MuiSvgIcon-root) {
  font-size: 3em;
}

@media only screen and (max-width: 640px) {
  .wrap {
    border-radius: 10px;
    background-color: white;
    padding: 1em;
    box-sizing: border-box;
    margin-bottom: 1em;
    box-shadow: 1px 4px 4px #E0E0E0;
  }

  .wrap h2 {
    margin-top: 0;
  }

  .more {
    margin-bottom: 0;
  }

  .container {
    width: 41vw;
  }

  .back {
    left: -0.5em;
  }
  
  .forward {
    right: -0.5em;
  }
}